// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  config: 'appsettings.json',
  // apiUrl: 'http://localhost:5050/',
  // imageUrl: 'http://localhost:5050/Files/',
  // apiUrl: 'https://api-maximus.paynowafrica.com/',
  // imageUrl: 'https://api-maximus.paynowafrica.com/Files/',
  // apiUrl: 'https://api-maximus.alias-solutions.net/',
  // imageUrl: 'https://api-maximus.alias-solutions.net/Files/',
  // apiUrl: 'https://app.alias-solutions.net:5003/',
  // imageUrl: 'https://app.alias-solutions.net:5003/Files/',
  // AWS Api
  // apiUrl: 'https://app.alias-solutions.net:5016/',
  // imageUrl: 'https://app.alias-solutions.net:5016/Files/',
  // Ahatanman Api
  // apiUrl: 'https://app.alias-solutions.net:5016/',
  // imageUrl: 'https://app.alias-solutions.net:5016/Files/',
  // Sic Api
  // apiUrl: 'https://app.alias-solutions.net:5017/',
  // imageUrl: 'https://app.alias-solutions.net:5017/Files/',
  // Zigloi Api
  // apiUrl: 'https://app.alias-solutions.net:5022/',
  // imageUrl: 'https://app.alias-solutions.net:5022/Files/',
  // New Api
  apiUrl: 'https://app.alias-solutions.net:5028/',
  imageUrl: 'https://app.alias-solutions.net:5028/Files/',
  // Aws Api
  // apiUrl: 'https://api.cross-switch.app:90/',
  // imageUrl: 'https://api.cross-switch.app:90/Files/',
  // apiUrl: 'http://3.212.216.125:87/',
  // imageUrl: 'http://3.212.216.125:87/Files/',
  // apiUrl: 'https://app.alias-solutions.net:5000/',
  // imageUrl: 'https://app.alias-solutions.net:5000/Files/',
  // apiUrls: 'https://api-fsp.paynowafrica.com/',
  apiUrls: 'https://app.alias-solutions.net:5002/',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
